import React from 'react';
import ReactDOMServer from 'react-dom/server';

/**
 * The React render function for keap signature.
 *
 * @param props
 * @return {JSX.Element}
 */
export function KeapSignatureViewer(props = {}) {
    const { values = {} } = props;

    const styles = {
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        lineHeight: '1.5',
    };

    if (window.signaturesByUserId && window.signaturesByUserId[values.userId]) {
        return (
            <div
                style={styles}
                className="user-signature"
                dangerouslySetInnerHTML={{ __html: window.signaturesByUserId[values.userId] }}
            />
        );
    }

    return (
        <div style={styles} className="user-signature">
            ~Owner.EmailSignature~
        </div>
    );
}

/**
 * Unlayer tool definition for keap signature.
 *
 * This can be passed directly to `unlayer.registerTool`
 */
export const KeapSignatureTool = {
    name: 'userSignature',
    label: 'Signature',
    icon: 'https://keap.app/images/unlayer/feather-icon.svg',
    supportedDisplayModes: ['email'],
    options: {
        content: {
            title: 'User',
            position: 1,
            options: {
                userId: {
                    label: 'Use signature for',
                    defaultValue: '',
                    widget: 'dropdown',
                },
            },
        },
    },
    values: {},
    renderer: {
        Viewer: KeapSignatureViewer,
        exporters: {
            email(values) {
                const props = { values, displayMode: 'email' };

                return ReactDOMServer.renderToStaticMarkup(<KeapSignatureViewer {...props} />);
            },
        },
    },
};
