import { KeapCanSpamFooterTool } from './can-spam-footer-tool';
import { KeapSignatureTool } from './signature-tool';
import { KeapFileDownloadPropertyEditor } from './file-download-tool';

export { setEmailLinkTypes, setEmailLinkTypesWithOptions } from '../unlayer-email-link-types';
export { KeapEmailFileDownloadTool } from './file-download-tool';
export { KeapEmailAppointmentTool } from './appointment-tool';
export { fullStoryInit, fullStoryIdentify, fullStoryEvent } from '@/shared/fullstory';
export { registerContentAssistantFeature } from '../unlayer-tools/features/addContentAssistant';
export { integrateWithTinyMCE } from '../unlayer-tools/tinymce/integrateTinyMCE';
export { KeapLogoTool, KeapLogoEditor } from '../unlayer-tools/custom-tools/brand-logo-tool/keap-brand-logo-tool';

window.unlayer.registerTool(KeapCanSpamFooterTool);
window.unlayer.registerTool(KeapSignatureTool);
window.unlayer.registerPropertyEditor(KeapFileDownloadPropertyEditor);
