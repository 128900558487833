import { intl } from '@/shared/intl';

export const logoToolOptions = () =>  {
    const logoConstants = {
        logo: {
            position: 1,
            title: intl.get('tool.brand.logo.label', { defaultMessage: 'Logo' }),
            options: {
                logoTool: {
                    label: intl.get('tool.brand.logo.label.title', { defaultMessage: 'Logos' }),
                    defaultValue: { businessKey: '' },
                    widget: 'keap#Logo#editor',
                },
                logoWidth : {
                    label: 'Auto Width',
                    defaultValue: {
                        autoWidth : true,
                        width:'100%',
                    },
                    widget: 'auto_width',
                },
                logoAlign : {
                    label: 'Align',
                    defaultValue:'center',
                    widget:'alignment',
                },
                logoAltText: {
                    label: 'Alternate Text',
                    defaultValue : '',
                    widget: 'text',
                },
            },
        },
        logoAction : {
            position: 2,
            title: 'Action',
            options: {
                imageLinkAction: {
                    label : 'Image Link',
                    defaultValue: '',
                    widget: 'link',
                },
            },
        },
    };

    return logoConstants;
};
