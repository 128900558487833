import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

import { KeapBrandLogoPropertyEditor } from './KeapBrandLogoPropertyEditor';
import { logoToolOptions } from './logo-tool.constant';
import { brandingLogoCss } from './brandLogoCss';
import { intl } from '@/shared/intl';
import logoPlaceholder from '@/icons/logo_placeholder.svg';
import { requestBrandLogos, requestBrandLogoDependencies } from './keap-brand-logo-requests.js';

export const KeapBrandingLogoViewer = (props) => {
    const { values: { logoAltText, logoAlign, logoWidth, logoTool, imageLinkAction }, exportMode = false } = props;
    const { width } = logoWidth;
    const dataTagId = imageLinkAction.customAttrs['data-tag-id'];

    const [businessKey, setBusinessKey] = useState(logoTool.businessKey);
    const [logoURL, setLogoURL] = useState('');
    const [logos, setLogos] = useState([]);
    const [logoDependencies, setLogoDependencies] = useState([]);

    if (logoTool.businessKey !== businessKey) {
        setBusinessKey(logoTool.businessKey);
    }

    const getLogoURL = () => {
        if (exportMode) return '';

        const logoId = logoDependencies.find((dep) => dep.dependencyKey === businessKey)?.dependencyId;
        const url = logos.find((l) => l.id === logoId)?.url;

        return url || null;
    };

    const refreshLogosAndDependencies = async (payload) => {
        const [newLogos, newLogoDeps] = await Promise.all([
            requestBrandLogos(payload),
            requestBrandLogoDependencies(payload),
        ]);

        setLogos(newLogos);
        setLogoDependencies(newLogoDeps);

        return {
            logos: newLogos,
            logoDependencies: newLogoDeps,
        };
    };

    useEffect(() => {
        refreshLogosAndDependencies({ useCache: true });
    }, []);

    useEffect(() => {
        if (!businessKey) return;

        const hasDependency = (logoDependencies) => {
            return logoDependencies.findIndex(({ dependencyKey }) => dependencyKey === businessKey) > -1;
        };

        if (hasDependency(logoDependencies)) return;

        (async () => {
            const { logoDependencies } = await refreshLogosAndDependencies({ useCache: true });

            if (hasDependency(logoDependencies)) return;

            await refreshLogosAndDependencies({ useCache: false });
        })();
    }, [businessKey]);

    useEffect(() => {
        const url = getLogoURL();

        if (url === logoURL) return;

        setLogoURL(url);
    }, [logos, logoDependencies]);

    return (
        <a href={imageLinkAction.url} target={imageLinkAction.target} data-tag-id={dataTagId}>
            <div style={{ justifyContent: logoAlign }} className='branding-logo-viewer'>
                {(logoURL || exportMode) ? <img style={{ width: width }} src={logoURL} alt={logoAltText} data-logo-dependency-key={logoTool.businessKey || ''} /> : <div style={{ width: width }}>
                    <svg data-testid="logo-placeholder-svg" viewBox={logoPlaceholder.viewBox}>
                        <use xlinkHref={`#${logoPlaceholder.id}`} />
                    </svg>
                </div>}
            </div>
        </a>
    );
};

export const KeapLogoTool = () => ({
    name: 'keap#logo_tool',
    label: intl.get('tool.brand.logo.label', { defaultMessage: 'Logo' }),
    icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.3536 8.14629L23.8534 0.646128C23.7597 0.552377 23.6322 0.499878 23.4997 0.499878H8.49968C8.36718 0.499878 8.23968 0.552377 8.14592 0.646128L0.645761 8.14629C0.552011 8.24004 0.499512 8.36754 0.499512 8.50005V23.5C0.499512 23.6325 0.552011 23.76 0.645761 23.8538L8.14592 31.354C8.23967 31.4477 8.36717 31.5002 8.49968 31.5002H23.4997C23.6322 31.5002 23.7597 31.4477 23.8534 31.354L31.3536 23.8538C31.4474 23.7601 31.4999 23.6326 31.4999 23.5V8.50005C31.4999 8.36755 31.4474 8.24005 31.3536 8.14629ZM1.49984 8.70754L8.7072 1.50018H18.0909C17.0946 1.98267 16.1696 2.62267 15.3634 3.42891L3.53075 15.2616C2.21575 13.6578 1.49949 11.6667 1.49949 9.56523L1.49984 8.70754ZM15.9997 27.7927L4.20704 16L15.9997 4.20738L27.7923 16L15.9997 27.7927ZM9.49984 30.5001H8.70733L1.49997 23.2928V13.9091C1.98247 14.9066 2.62247 15.8304 3.42871 16.6366L15.2613 28.4692C13.6576 29.7855 11.6665 30.5005 9.56503 30.5005L9.49984 30.5001ZM30.4998 23.2928L23.2925 30.5001H13.9088C14.9051 30.0176 15.8301 29.3776 16.6363 28.5714L28.4689 16.7388C29.7839 18.3425 30.5002 20.3336 30.5002 22.4351L30.4998 23.2928ZM28.5711 15.3641L16.7385 3.5315C18.3422 2.21525 20.3333 1.50024 22.4348 1.50024H23.2923L30.4997 8.7076V18.0913C30.0172 17.0938 29.3773 16.1704 28.5711 15.3641Z" fill="black" fill-opacity="0.6"/>
    <path d="M7.96915 0.469342L7.96914 0.469351L0.468985 7.96951L0.645461 8.14599L0.468985 7.96951C0.328081 8.11041 0.249512 8.30162 0.249512 8.50005V23.5C0.249512 23.6985 0.328084 23.8897 0.468974 24.0306L0.468985 24.0306L7.96914 31.5307C8.11005 31.6717 8.30125 31.7502 8.49968 31.7502H23.4997C23.6981 31.7502 23.8893 31.6716 24.0302 31.5308L24.0302 31.5307L31.5304 24.0306C31.6713 23.8897 31.7499 23.6985 31.7499 23.5V8.50005C31.7499 8.30162 31.6713 8.11043 31.5304 7.96952L31.3536 8.14629L31.5304 7.96951L24.0302 0.469351C23.8893 0.328447 23.6981 0.249878 23.4997 0.249878H8.49968C8.30126 0.249878 8.11006 0.32845 7.96915 0.469342ZM9.5012 30.2501H9.49984H8.81088L1.74997 23.1892V14.8936C2.16707 15.5827 2.66662 16.2281 3.25193 16.8134L14.8865 28.4479C13.3667 29.6174 11.5157 30.2504 9.56568 30.2505C9.56546 30.2505 9.56524 30.2505 9.56503 30.2505L9.5012 30.2501ZM1.7498 8.81113L8.81076 1.75018H17.107C16.4179 2.16724 15.772 2.66678 15.1866 3.25214L3.55217 14.8866C2.38348 13.3666 1.74951 11.5154 1.74949 9.56533C1.74949 9.5653 1.74949 9.56527 1.74949 9.56523L1.7498 8.81113ZM15.9997 27.4391L4.5606 16L15.9997 4.56093L27.4388 16L15.9997 27.4391ZM30.2499 23.1892L23.1889 30.2501H14.8927C15.5817 29.8331 16.2277 29.3335 16.8131 28.7482L28.4475 17.1137C29.6162 18.6337 30.2502 20.4849 30.2502 22.435C30.2502 22.435 30.2502 22.4351 30.2502 22.4351L30.2499 23.1892ZM28.7479 15.1874L17.1134 3.55284C18.6332 2.38317 20.4845 1.75024 22.4348 1.75024H23.1887L30.2497 8.81115V17.1068C29.8326 16.4178 29.3332 15.7727 28.7479 15.1874Z" stroke="black" stroke-opacity="0.8" stroke-width="0.5"/>
    </svg>`,
    supportedDisplayModes: ['web', 'email'],
    options: { ...logoToolOptions() },
    renderer: {
        Viewer : KeapBrandingLogoViewer,
        exporters : {
            web(values) {
                const props = { values, exportMode: true };

                return ReactDOMServer.renderToStaticMarkup(<KeapBrandingLogoViewer {...props }/>);
            },
            email(values) {
                const props = { values, exportMode: true };

                return ReactDOMServer.renderToStaticMarkup(<KeapBrandingLogoViewer {...props}/>);
            },
        },
        head: {
            css() {
                return brandingLogoCss;
            },
            js() {
                return;
            },
        },
    },
});

export const KeapLogoEditor = {
    name: 'keap#Logo#editor',
    Widget : KeapBrandLogoPropertyEditor,
};
