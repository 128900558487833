import React, { useState, useEffect } from 'react';
import style from './KeapBrandLogoPropertyEditor.scss';
import { Col, Row, Label, Badge, Button, Card, CardBody } from 'reactstrap';
import {
    requestBrandingCenter,
    requestBrandColors,
    requestBrandLogos,
} from './keap-brand-logo-requests';
import { intl } from '@/shared/intl';
import { PanelSection, PanelHeader } from '@/unlayer-tools/components/pages-panel-bar';

const KeapBrandLogoManager = (props) => {
    const { logos: initialLogos, onLogoSelect, onClose } = props;

    const [fetchedLogos, setFetchedLogos] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        (async () => {
            const data = await requestBrandLogos({ useCache: true });

            setFetchedLogos(data.logos);
        })();
    }, []);

    const handleManageLogo = async () => {
        try {
            setShowAlert(true);
            await requestBrandingCenter();
        } catch (error) {
            console.error('Error managing logo:', error);
        }
    };

    const handleRefreshBrandingData = async () => {
        try {
            const [logos, colors] = await Promise.all([
                requestBrandLogos(),
                requestBrandColors(),
            ]);

            setFetchedLogos(logos);

            window.unlayer?.setColorPickerConfig({
                colors: [{
                    id: 'common_colors',
                    label: 'editor.color.common_colors',
                    colors: colors?.map(({ color }) => color),
                }],
            });

            setShowAlert(false);
        } catch (error) {
            console.error('Error fetching logos:', error);
        }
    };

    const displayedLogos = Array.isArray(fetchedLogos) ? fetchedLogos : initialLogos;

    return (
        <div className={style.brandLogoManger}>
            <PanelSection>
                <PanelHeader className={style.PanelHeaderLogo} title='Branding Center' trailing={
                    <>
                        <div onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className="icon" aria-hidden="true"><path fillRule="evenodd" d="M18.707 6.707a1 1 0 0 0-1.414-1.414L12 10.586 6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 1 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12z"></path></svg>
                        </div>
                    </>
                }/>
            </PanelSection>
            <div className={style.brandLogoManagecontainer}>
                {showAlert && (
                    <Card className={style.refreshCard}>
                        <CardBody className={style.refreshCardBody}>
                            <div className='d-flex flex-column align-items-start'>
                                <p className={`${style.refreshText} mb-3`}>{intl.get('tool.brand.refresh.label', { defaultMessage : 'Refresh this page to see your updated logos and colors' })}</p>

                                <Button
                                    color="primary"
                                    onClick={handleRefreshBrandingData}
                                    className={style.refreshButton}
                                    data-testid="refresh-branding-data-button"
                                >
                                    {intl.get('tool.brand.refresh.button.text', { defaultMessage: 'Refresh Page' })}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                )}

                <Row className={style.logoManageHeaderRow}>
                    <Col>
                        <Label data-testid="manage-logos-header-label">{intl.get('tool.brand.logo.label', { defaultMessage: 'Logos' })}</Label>
                    </Col>
                    <Col xs='auto'>
                        <button
                            onClick={handleManageLogo}
                            className={style.manageLogoButton}
                            data-testid="manage-logos-button"
                        >
                            {intl.get('tool.brand.manage.logo.label', { defaultMessage: 'Manage Logos' })}
                        </button>
                    </Col>
                </Row>
                <Row className={style.logoManageRowLogosRow}>
                    {displayedLogos.map((logo, key) => (
                        <div className={style.logoContainer} key={key}>
                            {logo.primary && <Badge className={style.primaryBadge} color='primary'>Primary</Badge>}
                            <img
                                className={style.logoImageBranding}
                                key={key}
                                src={logo.url}
                                alt={`Logo ${key}`}
                                onClick={() => onLogoSelect(logo)}
                            />
                        </div>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default KeapBrandLogoManager;
