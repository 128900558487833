import React, { useEffect, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';

import style from './KeapBrandLogoPropertyEditor.scss';
import KeapBrandLogoManager from './KeapBrandLogoManager';
import {
    createLogoDependency,
    requestBrandLogos,
    requestBrandLogoDependencies,
} from './keap-brand-logo-requests';
import { intl } from '@/shared/intl';
import logoPlaceholder from '@/icons/logo_placeholder.svg';

export const KeapBrandLogoPropertyEditor = ({ values, updateValue }) => {
    const { logoTool } = values;

    const [showLogoManager, setShowlogoManager] = useState(false);
    const [selectedLogo, setSelectedLogo] = useState(null);

    const [brandLogos, setBrandLogos] = useState([]);
    const [logoDependencies, setLogoDependencies] = useState([]);

    const loadLogosAndDependencies = async () => {
        const logos = await requestBrandLogos({ useCache: true });
        const logoDeps = await requestBrandLogoDependencies({ useCache: true });

        setBrandLogos(logos);
        setLogoDependencies(logoDeps);

        return {
            logos,
            logoDependencies: logoDeps,
        };
    };

    useEffect(() => {
        (async () => {
            const { logoDependencies, logos } = await loadLogosAndDependencies();
            let logo;

            if (!logoTool.businessKey) {
                logo = logos.find((logo) => logo.primary);
            } else {
                const logoId = logoDependencies.find((dep) => dep.dependencyKey === logoTool.businessKey)?.dependencyId;

                logo = logos.find((l) => l.id === logoId) || null;
            }

            if (!logo) return;

            if (!logoTool.businessKey) {
                handleLogoSelect(logo);
            } else {
                setSelectedLogo(logo);
            }
        })();
    }, []);

    const handleBrandLogoManager = () => {
        setShowlogoManager(!showLogoManager);
    };

    const handleLogoSelect = async (logo) => {
        let businessKey;

        const index = logoDependencies.findIndex((el) => el.dependencyKey === logoTool.businessKey);
        const logoDeps = [...logoDependencies];

        if (index > -1) {
            // TODO: we also need to delete existing dependency from database
            logoDeps.splice(index, 1);
        }

        try {
            const data = await createLogoDependency({ logoId: logo.id });

            setLogoDependencies([...logoDeps, data]);

            businessKey = data.dependencyKey;
        } catch (e) {
            return;
        }

        setSelectedLogo(logo);
        updateValue({ businessKey });
        setShowlogoManager(false);
    };

    return (
        <>
            <div className={style.panelSection}>
                <Label className={style.labelStyle}>{intl.get('tool.brand.logo.label', { defaultMessage: 'Logo' })}</Label>
                <Button className={style.styledButton} size='sm' color="primary" onClick={handleBrandLogoManager}>{intl.get('tool.brand.more.logo.label', { defaultMessage: 'More Logos' })}</Button>
            </div>
            <Row className={style.logoEditorImageContainer}>
                <Col className={style.logoImageCol}>
                    {selectedLogo ? (
                        <img className={style.logoImage} src={selectedLogo.url} alt="brand-logo" />
                    ) : (
                        <div className={style.logoImage}>
                            <svg viewBox={logoPlaceholder.viewBox}>
                                <use xlinkHref={`#${logoPlaceholder.id}`} />
                            </svg>
                        </div>
                    )}
                </Col>
            </Row>
            {(showLogoManager && brandLogos)
                ?  <KeapBrandLogoManager logos={brandLogos} onLogoSelect={handleLogoSelect} onClose={handleBrandLogoManager} />
                : null
            }
        </>
    );
};

export default KeapBrandLogoPropertyEditor;
