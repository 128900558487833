import React from 'react';
import ReactDOMServer from 'react-dom/server';

/**
 * The React render function for keap-can-spam-footer.
 *
 * @param props
 * @return {JSX.Element}
 */
export function KeapCanSpamFooterViewer(props = {}) {
    const { values = {} } = props;
    const { alignment = 'center' } = values;

    return (
        <div className="can-spam-footer" style={{ textAlign: alignment }}>
            {values.additionalText && <p className="additional-text">{values.additionalText}</p>}

            <p>
                {values.linkPreText && <span className="pre-text">{values.linkPreText} </span>}
                <a href="~OptOut_BARD~" target="_blank" className="unsubscribe inf-track-no">Unsubscribe</a>
                {values.linkPostText && <span className="post-text"> {values.linkPostText}</span>}
            </p>

            <p className="address-block">~Company.CanSpamAddressBlock~</p>
        </div>
    );
}

/**
 * Unlayer tool definition for keap can spam footer.
 *
 * This can be passed directly to `unlayer.registerTool`
 */
export const KeapCanSpamFooterTool = {
    name: 'canSpamFooter',
    label: 'Opt Out',
    icon: 'fa-sign-out',
    enabled: false,
    supportedDisplayModes: ['email'],
    options: {
        content: {
            title: 'Content',
            position: 1,
            options: {
                additionalText: {
                    label: 'Additional footer text',
                    defaultValue: '',
                    widget: 'text',
                },
                linkPreText: {
                    label: 'Unsubscribe link pre-text',
                    defaultValue: '',
                    widget: 'text',
                },
                linkPostText: {
                    label: 'Unsubscribe link post-text',
                    defaultValue: '',
                    widget: 'text',
                },
                alignment: {
                    label: 'Alignment',
                    defaultValue: 'center',
                    widget: 'alignment',
                },
            },
        },
    },
    values: {},
    usageLimit: 1,
    renderer: {
        Viewer: KeapCanSpamFooterViewer,
        exporters: {
            email(values) {
                const props = { values, displayMode: 'email' };

                return ReactDOMServer.renderToStaticMarkup(<KeapCanSpamFooterViewer {...props} />);
            },
        },
    },
    transformer: (values) => {
        return {
            ...values,
            hideDesktop: false,
            hideMobile: false,
        };
    },
};
